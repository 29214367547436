import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

//Components
import { AuthenticateRoute, HttpErrorBox } from "smart-component-library";
import Impersonate from "../impersonate/Impersonate";
import Login from "../login/Login";
import Logout from "../logout/Logout";
//Services
import ConfigService from "../../services/ConfigService";

//I have no idea how to properly export and import the global styles so I don't have to point directly to them in the package.
import FontStyles from "smart-ui-library/src/shared/Font.styles";
import GlobalStyles from "smart-ui-library/src/shared/Global.styles";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingComplete: false,
      enabledConsoleLogs: false,
    };
  }

  async componentDidMount() {
    if (console) {
      let config = await ConfigService.getConfig();
      if (config?.enableConsoleLogs) {
        console.log("Console logs will be left on for debugging...");
      } else {
        console.log("Disabling console logs...");
        console.log = function () {};
        console.info = function () {};
      }
      this.setState({
        loadingComplete: true,
        enabledConsoleLogs: !!config?.enableConsoleLogs,
      });
    }
  }
  
  render() {
    return (
      <>
        <FontStyles />
        <GlobalStyles />
        <Switch>
          {/* the AuthenticateRoute component checks checks/gets user info and puts it in the state.auth for other apps to access. */}
          <Route exact path="/" component={AuthenticateRoute} />
          <Route
            exact
            path="/admin/impersonate"
            render={() => (
              <AuthenticateRoute>
                <Impersonate />
              </AuthenticateRoute>
            )}
          />
          <Route exact path="/resetpassword" component={Login} />
          {/* login route includes forget password and 2 factor auth components */}
          <Route exact path="/login" component={Login} />
          {/* logout will redirect back to login when logout is complete */}
          <Route exact path="/logout" component={Logout} />
          {/* anything else will show a 404 - redirecting back to root may cause a infinite redirect loop 
              as the "auth/" AuthenticateRoute component redirects the user back to the route they where trying to access 
              which will cause a loop if the path is something like auth/make_me_coffee - you will just end up with too much coffee  */}
          <Route exact path="*" render={() => <HttpErrorBox status={404} />} />
        </Switch>
      </>
    );
  }
}

const stateToProps = (state) => ({});

const dispatchToProps = (dispatch) => ({});

export default connect(stateToProps, dispatchToProps)(App);
